
/** @function Translator
 *  @description sets the translations strings
 *  @param {string} JSON string of translations
 */

class InviewTranslator {
    constructor() {
        this.translations = {};
    }

    translateIt(string) {
        if (this.translations[string] && this.translations[string] !== '') {
            return this.translations[string];
        }
        return string;
    }

    setStrings(strings) {
        this.translations = strings;
    }

    stranslate(count, string) {
        return this.translateIt(string + (count === 1 ? '' : 's'));
    }
}

export const Translator = new InviewTranslator();
